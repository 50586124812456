<template>
  <div class="summary-section">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummarySection',
};
</script>

<style scoped lang="scss">
.summary-section {
  padding: 5px 20px;
}
</style>
